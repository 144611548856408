<template>
 <div>
  <b-card bg-variant="light">
    <b-form @submit="onSubmit">
      <b-form-group 
        label-cols-lg="3"
        label="Reset Password"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        class="mb-0"
      >
        <ValidationProvider rules="required" name="Password" vid="password">
          <b-form-group 
            label-cols-sm="3"
            label-align-sm="right"
            slot-scope="{ valid, errors }"
            label="Password">      
              <b-form-input
                id="nested-password"
                type="password"
                v-model="user.password"
                :state="errors[0] ? false : (valid ? true : null)"
                placeholder="Enter new password">
              </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider rules="required" name="PasswordConfirmation" vid="password_confirmation">
          <b-form-group 
            label-cols-sm="3"
            label-align-sm="right"
            slot-scope="{ valid, errors }"
            label="Confirm Password">      
              <b-form-input
                id="nested-password-confirmation"
                type="password"
                v-model="user.password_confirmation"
                :state="errors[0] ? false : (valid ? true : null)"
                placeholder="Confirm new password">
              </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <br>
      <b-row>
        <b-col md="3"/>
        <b-col md="6">
          <b-button type="submit" block variant="primary">Change Password</b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  data () {
    return {
      user:{
        password: '',
        password_confirmation: ''
      },
      show: true
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      http_v1.post (
        'password_reset', {
          user: {
            password: this.user.password,
            password_confirmation: this.user.password_confirmation
          }
        }
      ).then(response => {
        if(response.data && response.data.auth_token) {
          localStorage.token = response.data.auth_token
          this.$router.push( {path: '/users/' + response.data.user.id + '/show'})
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    }
  },
  mounted() {
    if (this.$route.query.token_id) {
      http_v1.get (
        'password_reset_verification', {
          params: {
            token_id: this.$route.query.token_id
          }
        }
      ).then(response => {
        if(response.data && response.data.auth_token) {
          localStorage.token = response.data.auth_token
          // this.$router.back()
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    }
  }
}
</script>